@import './prismjs';

@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Prompt-Regular.ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Prompt';
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fafbfd;//#f4f6f8;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}
